import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../container/main'
import SEO from '../components/seo'

import ContentHeroNew from '../components/content-hero-new'
import Subheadline from '../components/content-subheadline'
import Container from '../components/container'
import ContentBlockLarge from '../components/content-block-large'
import ContentBlockSmall from '../components/content-block-small'
import { teamContainer, teamMember } from './about-us.module.scss'

const TeamMember = props => (
  <div className={teamMember}>
    <div className="img-wrapper">{props.img}</div>
    <span className="name">{props.name}</span>
    <span className="info">{props.info}</span>
  </div>
)

const IndexPage = ({ data }) => {
  return (
    <MainContainer>
      <SEO title="About Us" />

      {console.log('DATAAA', data)}

      <Container>
        <ContentHeroNew kicker="Our goal: The #1 Enterprise Productivity Hub" headline="We empower organizations to boost productivity" />
        <Container columns={3} style={{ marginTop: '-60px' }}>
          <ContentBlockSmall
            headline={<React.Fragment>More than {data.site.siteMetadata.teamsCount} teams</React.Fragment>}
            media={<StaticImage src="../assets/images/about-us-teams.png" alt={`More than ${data.site.siteMetadata.teamsCount} teams`} placeholder="blurred" />}
            centerAligned
          />
          <ContentBlockSmall
            headline="Users from 114 countries"
            media={<StaticImage src="../assets/images/about-us-countries.png" alt="Users from 114 countries" placeholder="blurred" />}
            centerAligned
          />
          <ContentBlockSmall headline="4,7 Stars on G2" media={<StaticImage src="../assets/images/about-us-g2.png" alt="4,7 Stars on G2" placeholder="blurred" />} centerAligned />
        </Container>
      </Container>

      <Container withBackground style={{ padding: '80px 0' }}>
        <ContentBlockLarge
          // kicker="Navigate your apps on every website"
          headline="We are on track to revolutionize the way you work"
          description={
            <React.Fragment>
              <p style={{ marginTop: '0', paddingTop: '0' }}>
                More and more people are working remotely and in distributed, sometimes cross-organizational teams. We all communicate on many different channels in various tools and many are using an
                increasing numbers of cloud solutions. We are accelerating every day towards a more complex work environment. With Basaas, we enable seamless digital work for every employee while
                increasing productivity at the same time.
              </p>
              <p>
                Although more than {data.site.siteMetadata.teamsCount} organizations from around the world are already benefiting from Basaas, we are just at the beginning of an exciting journey. We
                will continue to develop Basaas rapidly and have big plans for the future.
              </p>
            </React.Fragment>
          }
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/images/workplace-hero.png" alt="Optimize your app management" placeholder="blurred" />}
        />
      </Container>

      <Container style={{ padding: '80px 0' }}>
        <ContentBlockLarge
          // kicker="Navigate your apps on every website"
          headline="Streamline automated and manual workflows across all apps soon"
          description={
            <React.Fragment>
              <p style={{ marginTop: '0', paddingTop: '0' }}>
                Our vision is one overarching enterprise solution that unifies all apps, data and workflows. Streamlining workflows across apps and beyond teams is a key building block of the
                Connected Enterprise. We are already working hard on Basaas Flows, our third tool that will perfectly complement Basaas Workplace and Basaas Tasks. With Basaas Flows you will be
                enabled to manage all your workflows across your entire organization. You will soon be able to combine automated data flows with human interaction within one tool.
              </p>
              <p>
                To reach this Basaas is a key player in the Open Integration Hub project. Together with many other partners, we are developing an open-source framework for standardized data
                synchronization and process integration. By resolution of the German parliament (Deutsche Bundestag), Basaas receives a significant grant from the Federal Ministry of Economics for its
                development.
              </p>
              <p>
                <a href="https://www.openintegrationhub.org" target="_blank" rel="noreferrer">
                  Learn more about Open Integration Hub project
                </a>
              </p>
            </React.Fragment>
          }
          media={<StaticImage src="../assets/images/basaas-automation-preview.png" alt="Optimize your app management" placeholder="blurred" />}
          reverse
        />
      </Container>

      <Container>
        <Subheadline headline="Basaas Team" />
        <div className={teamContainer}>
          <TeamMember
            name="Daniel"
            info="Daniel is as CPO responsible for product management and service. As a proven expert in user experience, he is passionate about ensuring that users get the most out of Basaas. His goal is to revolutionize the daily work with apps and turn users into fans. Prior to Basaas, he was responsible for the UX area at BILD.de and helped build it up."
            img={<StaticImage src="../assets/images/avatar-daniel.jpg" alt="Selim" placeholder="blurred" />}
          />

          <TeamMember
            name="Frank"
            info="Frank is CEO and founder of Basaas and has many years of experience in SaaS businesses and cloud marketplaces. In the last 10 years Frank has supported many SaaS projects as an advisor and investor. He is also managing director of the Cloud Ecosystem, the independent ecosystem of the European SaaS community and project lead of the Open Integration Hub project."
            img={<StaticImage src="../assets/images/avatar-frank.jpg" alt="Selim" placeholder="blurred" />}
          />

          <TeamMember
            name="Guido"
            info="Being a TÜV-Certified data protection expert Guido is consulting companies in the field of data protection, IT security and process optimisation. Guido is Basaas’ Chief Compliance Officer. Prior to Basaas, Guido held various management positions in the IT & Telco Channel. Among other things, he spent many years with ALSO as VP Marketing, VP Sales and Head of Cloud Business."
            img={<StaticImage src="../assets/images/placeholder-square.png" alt="Selim" placeholder="blurred" />}
          />

          <TeamMember
            name="Jusup"
            info="Jusup is an experienced full-stack web developer, an API integration expert and part of our development team. His skills also include React, where he has a lot of experience. Before joining Basaas Jusup worked at a large publishing house for some years as well as for startups. During his free time he enjoys crafting robots."
            img={<StaticImage src="../assets/images/placeholder-square.png" alt="Selim" placeholder="blurred" />}
          />

          <TeamMember
            name="Hans"
            info="Hans is our expert for first class fullstack development. He has been instrumental in the development of the Basaas Workplace and especially the Basaas App. His technical focus is the integration of OpenID Connect (SSO) within the Basaas account. Prior to Basaas, he gained valuable experience as a software developer at a large publishing house."
            img={<StaticImage src="../assets/images/placeholder-square.png" alt="Selim" placeholder="blurred" />}
          />

          <TeamMember
            name="Selim"
            info="Selim is CTO and our technical-strategic head and responsible for the development of Basaas. He ensures that Basaas continues to set new technological standards. Through his team, the functionality of the Basaas platform and digital workplace grows with each sprint. In his last position as software architect at BILD, Selim accompanied many exciting projects and gained valuable experience."
            img={<StaticImage src="../assets/images/avatar-selim.jpg" alt="Selim" placeholder="blurred" />}
          />

          <TeamMember
            name="Sophie"
            info="Sophie is our Junior Data Scientist and part of the product management. Her mission is to help create a product that users love. Before joining Basaas, she studied business informatics and worked for a few years in product management and marketing for Cisco Germany."
            img={<StaticImage src="../assets/images/placeholder-square.png" alt="Selim" placeholder="blurred" />}
          />
        </div>
      </Container>
      <Container withBackground style={{ marginTop: '80px', paddingTop: '40px' }}>
        <ContentBlockLarge
          // kicker="Navigate your apps on every website"
          headline="Basaas office in Berlin is a place where revolutions begin"
          description={
            <React.Fragment>
              <p style={{ marginTop: '0', paddingTop: '0' }}>
                Basaas is located in the former community center of the Zionskirche (Zion church) in Prenzlauer Berg, perhaps the hippest district in Berlin. We are particularly inspired by the
                impressive history of our office. The old environmental library (Umwelt-Bibliothek) was located in the same rooms where we work today before the fall of the Berlin Wall.
              </p>
              <p>
                In addition to the collection of mostly banned books and magazines on environmental and human rights issues, the library also served as a place for conspiratorial meetings of GDR
                oppositionists. The journal Telegraph, published and printed by the Umwelt-Bibliothek in our todays office, was the only oppositional journalistic GDR publication. Until the revolution
                in autumn 1989, the activists of the Umwelt-Bibliothek were involved in all major actions of the Berlin opposition groups, which eventually led to the fall of the Berlin Wall.
              </p>
            </React.Fragment>
          }
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/images/basaas-berlin-headquarter.png" alt="Optimize your app management" placeholder="blurred" />}
        />
      </Container>
    </MainContainer>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        teamsCount
      }
    }
  }
`
