import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { contentBlockSmallContainer } from './index.module.scss'

const ContentBlockSmall = props => (
  <div className={`${contentBlockSmallContainer} ${props.media ? '' : 'full-width'} ${props.centerAligned ? 'align-center' : ''}`}>
    <div className="inner-wrapper">
      <div className="content">
        {props.media && <div className="media-container">{props.media}</div>}
        <h4 className="headline">
          {props.comingSoon && <div className="coming-soon" />}
          {props.headline}
        </h4>
        <span className="description">{props.description}</span>

        {props.actionLink &&
          (props.actionLinkIsExternal ? (
            <a className="action" href={props.actionLink} target={props.actionLinkInNewWindow ? '_blank' : '_self'} rel={props.actionLinkInNewWindow ? 'noreferrer' : ''}>
              {props.actionLinkText}
            </a>
          ) : (
            <Link className="action" to={props.actionLink}>
              {props.actionLinkText}
            </Link>
          ))}
      </div>
    </div>
  </div>
)

// ContentBlockAlternating.propTypes = {
//   mediaUrl: PropTypes.string,
//   mediaAlt: PropTypes.string,
//   kicker: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   body: PropTypes.string,
//   isExternalLink: PropTypes.bool,
//   linkTo: PropTypes.string,
//   linkText: PropTypes.string,
// };

// ContentBlockAlternating.defaultProps = {
//   isExternalLink: false,
// };

export default ContentBlockSmall
